import {
  reactAppDeferredRegistry,
  type NavigatorAppRegistrationFn,
  type DataRouterAppRegistrationFn,
  createNavigatorAppRegistration,
  createDataRouterAppRegistration,
} from './react-app-registry'
// Import the web component to get it registered on the window
import './ReactAppElement'

// NOTE: if the signature of this method changes, also update the react-app-name ESLint rule
// /ui/packages/eslint-plugin-github-monorepo/rules/react-app-name.js
export function registerNavigatorApp(appName: string, registration: NavigatorAppRegistrationFn) {
  return reactAppDeferredRegistry.register(appName, createNavigatorAppRegistration(registration))
}

export function registerDataRouterApp(appName: string, registration: DataRouterAppRegistrationFn) {
  return reactAppDeferredRegistry.register(appName, createDataRouterAppRegistration(registration))
}
