import {memo, type ComponentType, type ReactNode} from 'react'
import {Outlet, useLocation, useNavigation, type RouteObject} from 'react-router-dom'
import {useSoftNavLifecycle} from '../use-soft-nav-lifecycle'
import {CommonElements} from '../CommonElements'
import {RouterErrorBoundary} from './RouterErrorBoundary'
import {BaseProviders} from '../BaseProviders'

const SoftNavLifecycleListener = memo(function SoftNavLifecycleListener() {
  const location = useLocation()
  const navigation = useNavigation()
  const isNavigating = Boolean(navigation.location)
  useSoftNavLifecycle(location, isNavigating, null)
  return null
})

export function routesWithProviders(
  routes: RouteObject[],
  {
    ssrError,
    appName,
    wasServerRendered,
    children,
    HydrateFallback,
  }: {
    appName: string
    ssrError: HTMLScriptElement | undefined
    wasServerRendered: boolean
    children?: ReactNode
    HydrateFallback?: ComponentType
  },
): RouteObject[] {
  return [
    {
      errorElement: <RouterErrorBoundary />,
      HydrateFallback,
      element: (
        <BaseProviders appName={appName} wasServerRendered={wasServerRendered}>
          <Outlet />
          {children}
          <CommonElements ssrError={ssrError} />
          <SoftNavLifecycleListener />
        </BaseProviders>
      ),
      children: routes,
    },
  ]
}

try{ SoftNavLifecycleListener.displayName ||= 'SoftNavLifecycleListener' } catch {}