import {
  useQuery,
  type DataTag,
  type QueryKey,
  type SkipToken,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'
import {useLoaderData} from 'react-router-dom'

type QueryOptionsWithKey = UseQueryOptions<unknown, Error, unknown> & {
  initialData?: unknown
} & {
  queryKey: DataTag<QueryKey, unknown>
  queryFn: Exclude<UseQueryOptions<unknown, Error, unknown>['queryFn'], SkipToken>
}

export function useQueriesConfigs() {
  return useLoaderData() as Record<string, QueryOptionsWithKey>
}

export function useQueriesConfig(name: string) {
  const ctx = useQueriesConfigs()
  if (!ctx[name]) {
    throw new Error(`No query named ${name} is defined in the route configuration.`)
  }
  return ctx[name]
}

export function useRouteQuery<T>(name: string) {
  const config = useQueriesConfig(name)
  // Casting because config is not typed, and generic arguments to the `useQuery` hook would attempt to validate against the queryFn types.
  return useQuery(config) as UseQueryResult<T>
}
